import { render, staticRenderFns } from "./detail_local_delivery.vue?vue&type=template&id=39ae6b29&scoped=true&"
import script from "./detail_local_delivery.vue?vue&type=script&lang=js&"
export * from "./detail_local_delivery.vue?vue&type=script&lang=js&"
import style0 from "./detail_local_delivery.vue?vue&type=style&index=0&id=39ae6b29&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39ae6b29",
  null
  
)

export default component.exports